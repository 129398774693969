import { BlokProps, withStoryblokEditable } from "@helpers/StoryblokHelpers"
import { FactAdviceOverviewPageStoryblok } from "@storyblok-types"

import ItemList from "@/components/storyblok-routes/fact-advice/ItemList"
import SearchBlock from "@/components/storyblok-routes/fact-advice/SearchBlock"
import { Paragraph } from "@/components/ui-library/typography/Body"
import { FactAdviceListItem } from "@/types/NonSyncedTypes"
import { StoryblokServerComponent } from "@storyblok/react/rsc"
import Col from "@ui-library/grid/Col"
import Row from "@ui-library/grid/Row"
import GridWrapper from "@ui-library/grid/Wrapper"
import PageContainer from "@ui-library/layout/PageContainer"

interface FactAdviceOverviewProps {
  blok: BlokProps<FactAdviceOverviewPageStoryblok>["blok"]
  factAdviceItems: FactAdviceListItem[]
}

export const FactAdviceOverviewPage = ({ blok, factAdviceItems }: FactAdviceOverviewProps) => {
  return (
    <>
      <PageContainer>
        <GridWrapper>
          <Row justifyContent="center" justifyContentMobile="center">
            <Col grid={{ xs: 12, lg: 10, xl: 8 }} className="text-center">
              <h1 className="display-header">{blok.title}</h1>
              <Paragraph size="medium">{blok.preamble}</Paragraph>
            </Col>
          </Row>
        </GridWrapper>
        <SearchBlock placeholder={blok.search_bar_placeholder} />
        {blok.chips && (
          <GridWrapper {...withStoryblokEditable(blok)}>
            <Row justifyContent="center" justifyContentMobile="center">
              <Col grid={{ xs: 12, lg: 10, xl: 8 }} className="text-left lg:text-center">
                <div className="flex flex-wrap justify-center gap-2">
                  {blok.chips_heading && <h2 className="w-full">{blok.chips_heading}</h2>}

                  {blok.chips?.map(chip => <StoryblokServerComponent key={chip._uid} blok={chip} />)}
                </div>
              </Col>
            </Row>
          </GridWrapper>
        )}

        <GridWrapper background="surface" {...withStoryblokEditable(blok)}>
          <Row>
            {blok.sub_title && (
              <Col grid={{ xs: 12 }}>
                <h2>{blok.sub_title}</h2>
              </Col>
            )}

            <ItemList stories={factAdviceItems} />
          </Row>
        </GridWrapper>
      </PageContainer>
    </>
  )
}

export default FactAdviceOverviewPage
