import { BlokProps, withStoryblokEditable } from "@helpers/StoryblokHelpers"
import { DigitalCareCardSectionStoryblok } from "@storyblok-types"

import { StoryblokServerComponent } from "@storyblok/react/rsc"
import CardSection from "@ui-library/card/CardSection"

export const DigitalCareCardSection = ({ blok }: BlokProps<DigitalCareCardSectionStoryblok>) => {
  return (
    <>
      <CardSection
        {...withStoryblokEditable(blok)}
        title={blok.title}
        cards={blok.cards?.map(card => <StoryblokServerComponent key={card._uid} blok={card} />)}
      />

      {blok.button && blok.button.length > 0 && (
        <div className="mt-6 text-center lg:mt-10">
          {blok.button?.map(info => <StoryblokServerComponent key={info._uid} blok={info} />)}
        </div>
      )}
    </>
  )
}
