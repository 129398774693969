import { SbBlokData, storyblokEditable, StoryblokServerComponent } from "@storyblok/react/rsc"

import { BlokProps } from "@helpers/StoryblokHelpers"
import { ClinicConfigStoryblok, MapsSectionStoryblok } from "@storyblok-types"

import { useConfigStore } from "@/store/config-store"
import { FindHere } from "@ui-library/find-here/FindHere"

interface MapsSectionProps {
  blok: BlokProps<MapsSectionStoryblok>["blok"]
}

export type FindHereData = {
  title: string
  address: string
  lat?: string
  lng?: string
  public_transport_title: string
  public_transport_content: string
  car_title: string
  car_content: string
}

export const MapsSection = ({ blok }: MapsSectionProps) => {
  const config = useConfigStore.getState().config as ClinicConfigStoryblok

  const FindHereData = {
    title: blok.title,
    address: blok.address,
    lat: config?.latitude,
    lng: config?.longitude,
    public_transport_title: blok.public_transport_title,
    public_transport_content: blok.public_transport_content,
    car_title: blok.car_title,
    car_content: blok.car_content
  }

  return (
    <FindHere {...storyblokEditable(blok as SbBlokData)} data={FindHereData}>
      {blok.button?.map(link => <StoryblokServerComponent key={link._uid} blok={link} />)}
    </FindHere>
  )
}

export default MapsSection
