import { FilterTagStory, VaccinationLocation } from "@/types/NonSyncedTypes"
import { BlokProps, withStoryblokEditable } from "@helpers/StoryblokHelpers"
import { VaccinationBusOverviewPageStoryblok } from "@storyblok-types"
import { StoryblokServerComponent } from "@storyblok/react/rsc"
import PageContainer from "@ui-library/layout/PageContainer"

interface VaccinationBusOverviewPageProps {
  blok: BlokProps<VaccinationBusOverviewPageStoryblok>["blok"]
  locations: VaccinationLocation[]
  regionTags: FilterTagStory[]
}

export const VaccinationBusOverviewPage = ({ blok, locations, regionTags }: VaccinationBusOverviewPageProps) => {
  return (
    <PageContainer background={blok.background} {...withStoryblokEditable(blok)}>
      {blok.body?.map(nestedBlok => (
        <StoryblokServerComponent
          blok={nestedBlok}
          key={nestedBlok._uid}
          locations={locations}
          regionTags={regionTags}
        />
      ))}
    </PageContainer>
  )
}

export default VaccinationBusOverviewPage
