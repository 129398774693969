import { ISbStoryData, StoryblokServerComponent } from "@storyblok/react/rsc"

import { BlokProps, withStoryblokEditable } from "@helpers/StoryblokHelpers"
import { AuthorPageStoryblok, FactAdvicePageStoryblok } from "@storyblok-types"

import DefaultLatestArticle from "@/components/storyblok-routes/articles/DefaultLatestArticle"
import SideNavigation from "@/components/storyblok-routes/fact-advice/SideNavigation"
import { H2 } from "@/components/ui-library/typography/Headings"
import { ArticleFaktaRadPageScripts } from "@/helpers/seo-schema/ArticleFaktaRadPageScripts"
import { useStoryStore } from "@/store/story-store"
import Breadcrumbs from "@blocks/sections/Breadcrumbs"
import UIAccordion from "@ui-library/accordion/Accordion"
import Col from "@ui-library/grid/Col"
import Row from "@ui-library/grid/Row"
import GridWrapper from "@ui-library/grid/Wrapper"
import PageContainer from "@ui-library/layout/PageContainer"
import Richtext from "@ui-library/richtext/Richtext"
import { MedicalApprovedBadge } from "../components/MedicalApprovedBadge"
import ArticleInfo from "../sections/ArticleInfo"

interface FactAdvicePageProps {
  blok: BlokProps<FactAdvicePageStoryblok>["blok"]
  authors: ISbStoryData<AuthorPageStoryblok>[]
  contentInspectors: ISbStoryData<AuthorPageStoryblok>[]
}

export const FactAdvicePage = ({ blok, authors, contentInspectors }: FactAdvicePageProps) => {
  const showSidenavigation = blok.body && blok.body?.length > 1
  const story = useStoryStore.getState().story

  const grid = {
    xs: 12,
    lg: 8,
    xl: 6
  }

  return (
    <>
      <PageContainer>
        <GridWrapper
          className="mb-0 mt-8 rounded-b-none rounded-t-3xl bg-surface pt-12 md:my-16 md:rounded-b-none md:rounded-t-none md:bg-background md:pt-0"
          {...withStoryblokEditable(blok)}
        >
          <Row justifyContent="center" justifyContentMobile="center">
            <Col grid={grid}>
              <Breadcrumbs slug={story?.full_slug} />
              {blok.selected_heading && <h1>{blok.selected_heading}</h1>}
              {blok.content_inspector && <MedicalApprovedBadge />}
              {blok.page_intro && <Richtext preamble content={blok.page_intro} />}
            </Col>
          </Row>
        </GridWrapper>

        <GridWrapper background="surface" className="my-0 rounded-none md:rounded-3xl">
          <Row
            justifyContent={showSidenavigation ? "end" : "center"}
            justifyContentMobile={showSidenavigation ? "end" : "center"}
            className="items-start md:items-start"
          >
            <Col grid={grid} className="order-2 lg:order-2">
              {blok.body?.map(nestedBlok => <StoryblokServerComponent blok={nestedBlok} key={nestedBlok._uid} />)}

              {blok.faq_intro && (
                <article className="mb-8">
                  <Richtext content={blok.faq_intro} />
                </article>
              )}

              {blok.faq && (
                <UIAccordion type="single">
                  {blok.faq?.map(nestedBlok => <StoryblokServerComponent blok={nestedBlok} key={nestedBlok._uid} />)}
                </UIAccordion>
              )}

              {blok.related_chips && blok.related_chips.length > 0 && (
                <>
                  <H2 className="mt-8">{blok.related_heading}</H2>
                  <div className="mt-6 flex flex-wrap justify-start gap-2">
                    {blok.related_chips?.map(chip => <StoryblokServerComponent key={chip._uid} blok={chip} />)}
                  </div>
                </>
              )}
            </Col>

            {showSidenavigation && (
              <Col
                grid={{ xs: 12, lg: 4, xl: 3 }}
                className="order-1 mb-8 hidden lg:sticky lg:top-28 lg:order-2 lg:mb-0 lg:block"
              >
                <SideNavigation items={blok.body} />
              </Col>
            )}
          </Row>
        </GridWrapper>

        <GridWrapper>
          <Row justifyContent="center" justifyContentMobile="center">
            <Col grid={grid}>
              <ArticleInfo
                storyName={story?.name}
                published_date={blok.published_date}
                updated_date={blok.updated_date}
                author={blok.author}
                content_inspector={blok.content_inspector}
                authorsPages={authors}
                contentInspectorsPages={contentInspectors}
              />
            </Col>
          </Row>
        </GridWrapper>

        {blok.blocks?.map(nestedBlok => <StoryblokServerComponent blok={nestedBlok} key={nestedBlok._uid} />)}

        <DefaultLatestArticle blok={blok} />
        {story && <ArticleFaktaRadPageScripts story={story} blok={blok} />}
      </PageContainer>
    </>
  )
}

export default FactAdvicePage
