import ShareArticle from "@/components/ui-library/share/ShareArticle"
import { ElementId } from "@/consts/element-id"
import { formatDate } from "@/lib/formatDate"
import { AuthorPageStoryblok, SourceLinkStoryblok } from "@storyblok-types"
import { StoryblokServerComponent } from "@storyblok/react/rsc"
import { Paragraph } from "@ui-library/typography/Body"
import { Label } from "@ui-library/typography/Labels"
import Image from "next/image"
import Link from "next/link"
import { ISbStoryData } from "storyblok-js-client"

interface ArticleInfoProps {
  storyName?: string
  published_date: string
  updated_date?: string

  author?: string
  content_inspector?: string

  authorsPages: ISbStoryData<AuthorPageStoryblok>[]
  contentInspectorsPages: ISbStoryData<AuthorPageStoryblok>[]

  sources?: SourceLinkStoryblok[]
}

interface AuthorInfoProps {
  author: ISbStoryData<AuthorPageStoryblok>
}

function optimizeImage(content: AuthorPageStoryblok): string {
  const usingAvatar = !!content.avatar_image
  const image = content.avatar_image ?? content.featured_image
  if (!image.focus || usingAvatar) {
    return image.filename
  }
  const distanceAroundFocalPoint = 400
  const [firstPair, secondPair] = image.focus.split(":")
  const [x1, y1] = firstPair.split("x").map(Number)
  const [x2, y2] = secondPair.split("x").map(Number)
  const topLeftX = x1 - distanceAroundFocalPoint
  const topLeftY = y1 - distanceAroundFocalPoint
  const bottomRightX = x2 + distanceAroundFocalPoint
  const bottomRightY = y2 + distanceAroundFocalPoint
  return `${image.filename}/m/${topLeftX}x${topLeftY}:${bottomRightX}x${bottomRightY}`
}

const AuthorInfo = ({ author: { content, full_slug } }: AuthorInfoProps) => {
  return (
    <Link className="mb-2 flex items-center" href={`/${full_slug}`}>
      {(content.avatar_image || content.featured_image) && (
        <Image
          width={30}
          height={30}
          className="mr-2 inline-block rounded-full object-cover"
          src={optimizeImage(content)}
          alt={content.full_name_text}
        />
      )}
      <div className="flex flex-wrap items-center">
        <span className="inline-link mr-1">{content.full_name_text}</span>
        {content.position_text && <span className="text-base">{content.position_text}</span>}
      </div>
    </Link>
  )
}

export const ArticleInfo = ({
  storyName,
  published_date,
  updated_date,
  sources,
  author,
  content_inspector,
  authorsPages,
  contentInspectorsPages
}: ArticleInfoProps) => {
  const publishedDate = formatDate(published_date)
  const updatedDate = formatDate(updated_date || "")

  const hasAuthor = author || (authorsPages && authorsPages.length !== 0)
  const hasContentInspector = content_inspector || (contentInspectorsPages && contentInspectorsPages.length !== 0)

  const showEditorsSection = hasAuthor || hasContentInspector

  return (
    <>
      <ShareArticle articleTitle={storyName} />

      {(sources ?? []).length != 0 && (
        <div className="mb-4 border-b border-neutral-30 pb-4">
          <Label size="small">Källor:</Label>
          <ul className="list-none">
            {sources?.map(source => (
              <li key={source._uid}>
                <StoryblokServerComponent blok={source} />
              </li>
            ))}
          </ul>
        </div>
      )}

      {showEditorsSection && (
        <div className="mb-4 border-b border-neutral-30 pb-4">
          {hasAuthor && (
            <>
              <Label size="small">Redaktör:</Label>
              <Paragraph size="small" asChild>
                {authorsPages?.length != 0 ? (
                  <>{authorsPages?.map((authorPage, i) => <AuthorInfo key={i} author={authorPage}></AuthorInfo>)}</>
                ) : (
                  <span className="mb-2 block">{author}</span>
                )}
              </Paragraph>
            </>
          )}

          {hasContentInspector && (
            <>
              <Label size="small" id={ElementId.MedialRedactorInfo}>
                Granskare:
              </Label>
              <Paragraph size="small" asChild>
                {contentInspectorsPages?.length != 0 ? (
                  <>
                    {contentInspectorsPages?.map((authorPage, i) => (
                      <AuthorInfo key={i} author={authorPage}></AuthorInfo>
                    ))}
                  </>
                ) : (
                  <span className="mb-2 block">{content_inspector}</span>
                )}
              </Paragraph>
            </>
          )}
        </div>
      )}

      <div>
        {publishedDate && (
          <>
            <Label size="small">Publicerat datum:</Label>
            <Paragraph size="small" asChild>
              <span className="mb-2 block">{publishedDate}</span>
            </Paragraph>
          </>
        )}

        {updatedDate && (
          <>
            <Label size="small">Senast granskad:</Label>
            <Paragraph size="small" asChild>
              <span className="mb-2 block">{updatedDate}</span>
            </Paragraph>
          </>
        )}
      </div>
    </>
  )
}

export default ArticleInfo
