"use client"

import { cn } from "@/utils/utils"
import { BlokProps, withStoryblokEditable } from "@helpers/StoryblokHelpers"
import { DigitalCareCardStoryblok } from "@storyblok-types"
import { Paragraph } from "@ui-library/typography/Body"

import Image from "next/image"
import { isMobile } from "react-device-detect"

export const DigitalCareCard = ({ blok }: BlokProps<DigitalCareCardStoryblok>) => {
  const showQrCode = !isMobile && blok.alternative_desktop_content && blok.desktop_image
  const showDesktopContent = !isMobile && blok.alternative_desktop_content

  return (
    <article
      className="h-full whitespace-break-spaces break-words rounded-2xl bg-surface p-6 shadow-elevation-1 lg:p-8"
      {...withStoryblokEditable(blok)}
    >
      <figure className="mb-6 block max-h-[84px] lg:max-h-[164px]">
        {blok.image?.filename && (
          <Image
            src={blok.image.filename || ""}
            alt={blok.image.alt || ""}
            width={200}
            height={160}
            className={cn("mx-auto my-0 hidden h-full max-h-[84px] w-auto lg:max-h-[164px]", !showQrCode && "block")}
          />
        )}
        {blok.desktop_image?.filename && (
          <Image
            src={blok.desktop_image.filename || ""}
            alt={blok.desktop_image.alt || ""}
            width={120}
            height={120}
            className={cn("mx-auto my-0 hidden h-full max-h-[84px] w-auto lg:max-h-[164px]", showQrCode && "block")}
          />
        )}
      </figure>
      <div className="text-center">
        {blok.heading && <h3 className={cn("block", showDesktopContent && "hidden")}>{blok.heading}</h3>}
        {blok.desktop_heading && (
          <h3 className={cn("hidden", showDesktopContent && "block")}>{blok.desktop_heading}</h3>
        )}

        <Paragraph size="small" className={cn("hidden", showDesktopContent && "block")}>
          {blok.desktop_content}
        </Paragraph>
        <Paragraph size="small" className={cn("block", showDesktopContent && "hidden")}>
          {blok.content}
        </Paragraph>
      </div>
    </article>
  )
}

export default DigitalCareCard
