import * as Blocks from "@components/blocks/export"
import { apiPlugin, storyblokInit } from "@storyblok/react/rsc"

export const getStoryblokApi = storyblokInit({
  accessToken: process.env.STORYBLOK_ACCESS_TOKEN,
  use: [apiPlugin],
  apiOptions: {
    cache: { type: "memory", clear: "auto" }
  },
  components: Object.fromEntries(Object.entries(Blocks))
})
