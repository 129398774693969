import { ClinicPageScripts } from "@/helpers/seo-schema/ClinicPageScripts"
import { BlokProps, withStoryblokEditable } from "@helpers/StoryblokHelpers"
import { ClinicPageStoryblok } from "@storyblok-types"
import { StoryblokServerComponent } from "@storyblok/react/rsc"
import PageContainer from "@ui-library/layout/PageContainer"

interface ClinicPageProps {
  blok: BlokProps<ClinicPageStoryblok>["blok"]
}

export const ClinicPage = ({ blok }: ClinicPageProps) => {
  return (
    <>
      <PageContainer background={blok.background} extraHeight={false} {...withStoryblokEditable(blok)}>
        {blok.body?.map(nestedBlok => <StoryblokServerComponent blok={nestedBlok} key={nestedBlok._uid} />)}
      </PageContainer>
      {blok && <ClinicPageScripts blok={blok} />}
    </>
  )
}

export default ClinicPage
