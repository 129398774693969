"use client"

import * as ScrollArea from "@radix-ui/react-scroll-area"
import * as Select from "@radix-ui/react-select"
import React from "react"

import SelectDownIcon from "@layout/icons/SelectDownIcon"
import { Paragraph } from "@ui-library/typography/Body"
import { cn } from "@utils/utils"

export interface SelectItem {
  value: string
  label: string
}

export type SelectMenuProps = React.HTMLAttributes<HTMLDivElement> & {
  placeholder?: string
  items: SelectItem[]
  selectedFilter?: string
  param: string
  onChange?: (value: string, param: string) => void
}

export const SelectMenu = ({ placeholder, items, onChange, selectedFilter, param, className }: SelectMenuProps) => {
  const handleSelectChange = (selectedItem: string) => {
    if (onChange) {
      onChange(selectedItem, param)
    }
  }

  const isStringInArrayOfObjects = (arr: SelectItem[], searchString?: string) => {
    for (const obj of arr) {
      if (obj.value === searchString) {
        return true
      }
    }
    return false
  }

  const result = isStringInArrayOfObjects(items, selectedFilter)

  return (
    <SelectRoot showSelected={result} handleChange={handleSelectChange} selectedItem={selectedFilter}>
      <SelectTrigger className={className}>
        <Paragraph size="medium" className="text-on-surface opacity-70" asChild>
          <Select.Value placeholder={placeholder} />
        </Paragraph>
      </SelectTrigger>

      <SelectContent>
        <Select.Group>
          {items.map((item, index) => (
            <SelectItem key={index} value={item.value}>
              {item.label}
            </SelectItem>
          ))}
        </Select.Group>
      </SelectContent>
    </SelectRoot>
  )
}

export default SelectMenu

type SelectRootProps = {
  showSelected: boolean
  selectedItem?: string
  children?: React.ReactNode
  handleChange?: (value: string) => void
}

const SelectRoot = ({ showSelected, selectedItem, children, handleChange }: SelectRootProps) => {
  if (!showSelected) {
    return <Select.Root onValueChange={handleChange}>{children}</Select.Root>
  } else {
    return (
      <Select.Root onValueChange={handleChange} value={selectedItem}>
        {children}
      </Select.Root>
    )
  }
}

type SelectTriggerProps = {
  children?: React.ReactNode
  className?: string
}

const SelectTrigger = React.forwardRef<HTMLButtonElement, SelectTriggerProps>(
  ({ className, children, ...props }, ref) => (
    <Select.Trigger
      ref={ref}
      className={cn(
        "group flex w-full cursor-pointer items-center justify-between rounded-lg border-2 border-neutral-30 bg-surface p-3 focus:outline-none aria-expanded:border-primary-50",
        className
      )}
      {...props}
    >
      {children}
      <Select.Icon asChild>
        <SelectDownIcon className="group-aria-expanded:rotate-180" />
      </Select.Icon>
    </Select.Trigger>
  )
)
SelectTrigger.displayName = "SelectTrigger"

type SelectContentProps = {
  children: React.ReactNode
  className?: string
}

const SelectContent = React.forwardRef<HTMLDivElement, SelectContentProps>(({ className, children, ...props }, ref) => (
  <Select.Portal>
    <Select.Content
      ref={ref}
      className={cn("dropdownContent z-10 h-80 w-full rounded-lg bg-surface p-3 shadow-elevation-1", className)}
      {...props}
      position="popper"
      sideOffset={5}
    >
      <ScrollArea.Root className="h-full w-full" type="auto">
        <Select.Viewport asChild className="max-h-80 w-full">
          <ScrollArea.Viewport style={{ overflowY: undefined }} className="h-full w-full">
            {children}
          </ScrollArea.Viewport>
        </Select.Viewport>
        <ScrollArea.Scrollbar orientation="vertical" className="w-2 p-[2px]">
          <ScrollArea.Thumb className="rounded-[3px] bg-grey" />
        </ScrollArea.Scrollbar>
      </ScrollArea.Root>
    </Select.Content>
  </Select.Portal>
))
SelectContent.displayName = "SelectContent"

const SelectItem = React.forwardRef<
  React.ElementRef<typeof Select.Item>,
  React.ComponentPropsWithoutRef<typeof Select.Item>
>(({ className, children, value, ...props }, ref) => (
  <Select.Item
    ref={ref}
    className={cn(
      "rounded-lg p-3 hover:bg-primary-50 hover:text-on-secondary-50 focus:bg-primary-50 focus:text-on-primary-50 focus:outline-none",
      className
    )}
    value={value || crypto.randomUUID()}
    {...props}
  >
    <Select.ItemText>{children}</Select.ItemText>
  </Select.Item>
))
SelectItem.displayName = "SelectItem"
