import Image from "next/image"

import { BlokProps, withStoryblokEditable } from "@helpers/StoryblokHelpers"
import { calculateReadingTimeFromMultidimensionalArray } from "@lib/calculateReadingTimeFromRichText"
import { formatDate } from "@lib/formatDate"
import { ArticlePageStoryblok, AuthorPageStoryblok } from "@storyblok-types"

import Breadcrumbs from "@/components/blocks/sections/Breadcrumbs"
import DefaultLatestArticle from "@/components/storyblok-routes/articles/DefaultLatestArticle"
import { ArticleFaktaRadPageScripts } from "@/helpers/seo-schema/ArticleFaktaRadPageScripts"
import { useStoryStore } from "@/store/story-store"
import { ISbStoryData, StoryblokServerComponent } from "@storyblok/react/rsc"
import Col from "@ui-library/grid/Col"
import Row from "@ui-library/grid/Row"
import GridWrapper from "@ui-library/grid/Wrapper"
import PageContainer from "@ui-library/layout/PageContainer"
import SubscriptionSection from "@ui-library/section/SubscriptionSection"
import { Paragraph } from "@ui-library/typography/Body"
import { MedicalApprovedBadge } from "../components/MedicalApprovedBadge"
import ArticleInfo from "../sections/ArticleInfo"

interface ArticlePageProps {
  blok: BlokProps<ArticlePageStoryblok>["blok"]
  authors: ISbStoryData<AuthorPageStoryblok>[]
  contentInspectors: ISbStoryData<AuthorPageStoryblok>[]
}

export const ArticlePage = ({ blok, authors, contentInspectors }: ArticlePageProps) => {
  const story = useStoryStore.getState().story
  const publishedDate = formatDate(blok.published_date)
  const readingTime = calculateReadingTimeFromMultidimensionalArray(blok.body, blok.preamble || "")

  const grid = {
    xs: 12,
    lg: 8,
    xl: 6
  }

  return (
    <>
      <PageContainer>
        <GridWrapper
          className="mb-0 mt-8 rounded-b-none rounded-t-3xl bg-surface pt-12 md:my-16 md:rounded-b-none md:rounded-t-none md:bg-background md:pt-0"
          {...withStoryblokEditable(blok)}
        >
          <Row justifyContent="center" justifyContentMobile="center">
            <Col grid={grid}>
              <Breadcrumbs slug={story?.full_slug} />
              <h1>{story?.name}</h1>
              <div className="flex justify-between">
                <span className="text-neutral-50">{`${publishedDate} ・ ${readingTime} min`}</span>
                {blok.content_inspector && <MedicalApprovedBadge />}
              </div>
            </Col>

            <Col grid={{ xs: 12, lg: 10, xl: 8 }}>
              {blok.featured_image && (
                <figure className="relative mb-6 aspect-[1.85] w-full overflow-hidden rounded-2xl lg:mb-10 lg:aspect-[2.5] lg:rounded-3xl">
                  <Image
                    src={blok.featured_image.filename || ""}
                    quality={20}
                    width={840}
                    height={0}
                    priority
                    alt={blok.featured_image.alt || ""}
                    className="absolute inset-0 h-full w-full object-cover"
                  />
                </figure>
              )}
            </Col>

            <Col grid={grid}>
              <Paragraph size="large">{blok.preamble}</Paragraph>
            </Col>
          </Row>
        </GridWrapper>

        <GridWrapper background="surface" className="my-0 rounded-none md:my-0 md:rounded-3xl">
          <Row justifyContent="center" justifyContentMobile="center">
            <Col grid={grid}>
              {blok.body?.map(nestedBlok => <StoryblokServerComponent blok={nestedBlok} key={nestedBlok._uid} />)}

              {!blok.hide_newsletter_subscription && <SubscriptionSection />}
            </Col>
          </Row>
        </GridWrapper>

        <GridWrapper>
          <Row justifyContent="center" justifyContentMobile="center">
            <Col grid={grid}>
              <ArticleInfo
                storyName={story?.name}
                published_date={blok.published_date}
                updated_date={blok.updated_date}
                author={blok.author}
                content_inspector={blok.content_inspector}
                authorsPages={authors}
                contentInspectorsPages={contentInspectors}
                sources={blok.sources}
              />
            </Col>
          </Row>
        </GridWrapper>

        {blok.blocks?.map(nestedBlok => <StoryblokServerComponent blok={nestedBlok} key={nestedBlok._uid} />)}

        <DefaultLatestArticle blok={blok} />
        {story && <ArticleFaktaRadPageScripts story={story} blok={blok} featuredImage={blok.featured_image.filename} />}
      </PageContainer>
    </>
  )
}

export default ArticlePage
