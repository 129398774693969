import { SbBlokData, storyblokEditable, StoryblokServerComponent } from "@storyblok/react/rsc"

import { BlokProps } from "@helpers/StoryblokHelpers"
import { ConfigStoryblok, StartpageHeroStoryblok } from "@storyblok-types"
import Image from "next/image"

import { Label } from "@/components/ui-library/typography/Labels"
import CheckCircle from "@/layout/icons/CheckCircle"
import { useConfigStore } from "@/store/config-store"
import Col from "@ui-library/grid/Col"
import Row from "@ui-library/grid/Row"
import Richtext from "@ui-library/richtext/Richtext"

interface StartpageHeroSectionProps {
  blok: BlokProps<StartpageHeroStoryblok>["blok"]
}

export const StartpageHeroSection = ({ blok }: StartpageHeroSectionProps) => {
  const config = useConfigStore.getState().config as ConfigStoryblok

  return (
    <section className="relative mb-32 w-full" {...storyblokEditable(blok as SbBlokData)}>
      <Row className="relative z-10 text-center">
        <Col grid={{ xs: 12, md: 12, lg: 6, xl: 6 }} className="py-6 lg:py-12 lg:pb-32">
          <Richtext content={blok.content} hero={true} />

          {blok.bullets && (
            <ul className="mx-auto inline-flex w-full flex-wrap text-left lg:px-16">
              {blok.bullets?.map(bullet => {
                return (
                  <li key={bullet._uid} className="my-1 inline-flex">
                    <CheckCircle className="mr-2" />
                    <Label size="medium">{bullet.text}</Label>
                  </li>
                )
              })}
            </ul>
          )}

          {blok.button && (
            <div className="mt-8 inline-flex flex-col items-center justify-center gap-4 max-lg:w-full">
              {blok.button?.map(blok => <StoryblokServerComponent key={blok._uid} blok={blok} className="w-full" />)}
            </div>
          )}
        </Col>
      </Row>

      <figure className="z-0 w-full lg:absolute lg:bottom-0 lg:right-0 lg:top-0 lg:w-2/4">
        <Image
          src={blok.image.filename || ""}
          priority
          width={1920}
          height={0}
          sizes="100vw"
          alt={blok.image.alt || ""}
          className="h-full w-full lg:absolute lg:inset-0 lg:object-cover lg:object-top"
        />
      </figure>

      {config?.testimonials && (
        <section className="absolute left-0 top-full w-full -translate-y-2/4 px-4">
          <figure className="mx-auto flex w-full max-w-[1200px] flex-wrap justify-between rounded-3xl bg-secondary-10 px-8 py-4 lg:px-10 lg:py-8">
            {config.testimonials?.map(blok => <StoryblokServerComponent key={blok._uid} blok={blok} />)}
          </figure>
        </section>
      )}
    </section>
  )
}

export default StartpageHeroSection
