import { BlokProps } from "@helpers/StoryblokHelpers"
import { PageIntroSectionStoryblok } from "@storyblok-types"
import { SbBlokData, storyblokEditable, StoryblokServerComponent } from "@storyblok/react/rsc"

import Col from "@ui-library/grid/Col"
import Row from "@ui-library/grid/Row"
import GridWrapper from "@ui-library/grid/Wrapper"
import { Paragraph } from "@ui-library/typography/Body"

export const PageIntroSection = ({ blok }: BlokProps<PageIntroSectionStoryblok>) => {
  return (
    <GridWrapper {...storyblokEditable(blok as SbBlokData)}>
      <Row justifyContent="center" justifyContentMobile="center">
        <Col grid={{ xs: 12, lg: 10, xl: 8 }} className="text-left lg:text-center">
          {blok.heading && <h1 className="display-header">{blok.heading}</h1>}
          {blok.content && <Paragraph size="large">{blok.content}</Paragraph>}

          {blok.button && blok.button.length > 0 && (
            <div className="mt-6 lg:mt-10">
              {blok.button?.map(blok => <StoryblokServerComponent key={blok._uid} blok={blok} />)}
            </div>
          )}
        </Col>
      </Row>
    </GridWrapper>
  )
}

export default PageIntroSection
