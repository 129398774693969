"use client"

import { trackEvent } from "@/lib/amplitude/amplitude"
import { BlokProps } from "@helpers/StoryblokHelpers"
import { ErrorMessageStoryblok } from "@storyblok-types"
import { StoryblokServerComponent } from "@storyblok/react/rsc"
import Col from "@ui-library/grid/Col"
import Row from "@ui-library/grid/Row"
import GridWrapper from "@ui-library/grid/Wrapper"
import Richtext from "@ui-library/richtext/Richtext"

import Image from "next/image"
import { usePathname } from "next/navigation"
import { useEffect, useState } from "react"

export const ErrorMessage = ({ blok }: BlokProps<ErrorMessageStoryblok>) => {
  const pathname = usePathname()
  const [isMounted, setIsMounted] = useState<boolean>(false)

  useEffect(() => {
    if (!isMounted) {
      trackEvent("web.pageview.404_page", { page_url: pathname })
      setIsMounted(true)
    }
  }, [pathname, isMounted])

  return (
    <GridWrapper className="max-lg:bg-surface max-lg:py-8">
      <Row alignItems="center">
        <Col grid={{ xs: 12, lg: 6 }} className="max-lg:order-2">
          <Richtext content={blok.content} className="mb-8" neutralParagraphs />

          {blok.buttons && (
            <div className="mt-4 flex justify-start gap-4 lg:mt-16">
              {blok.buttons?.map(blok => <StoryblokServerComponent key={blok._uid} blok={blok} />)}
            </div>
          )}
        </Col>

        <Col grid={{ xs: 12, lg: 6 }} className="max-lg:order-1 max-lg:mb-8">
          <Image
            src={blok.illustration?.filename || ""}
            alt={blok.illustration?.alt || ""}
            priority
            width={600}
            height={500}
          />
        </Col>
      </Row>
    </GridWrapper>
  )
}

export default ErrorMessage
