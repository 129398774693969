import { SbBlokData, storyblokEditable, StoryblokServerComponent } from "@storyblok/react/rsc"

import { BlokProps } from "@helpers/StoryblokHelpers"
import { ContactCardStoryblok } from "@storyblok-types"

import { urlParser } from "@/lib/urlParser"
import ArrowLink from "@ui-library/link/ArrowLink"
import { Paragraph } from "@ui-library/typography/Body"

export const ContactCard = ({ blok }: BlokProps<ContactCardStoryblok>) => {
  return (
    <div
      className="flex h-full flex-col justify-between rounded-2xl bg-surface p-6 shadow-elevation-1 lg:min-h-[410px] lg:p-8"
      {...storyblokEditable(blok as SbBlokData)}
    >
      <div className="mb-8">
        <h3>{blok.title}</h3>
        {blok.items && (
          <ul>
            {blok.items?.map(item => (
              <li key={item._uid} className="mb-4 flex flex-wrap items-start">
                <StoryblokServerComponent blok={item} />
              </li>
            ))}
          </ul>
        )}

        {blok.description && <Paragraph size="small">{blok.description}</Paragraph>}
      </div>

      {blok.link?.cached_url && blok.link_text && (
        <ArrowLink text={blok.link_text} url={urlParser(blok.link.cached_url)} color="primary" />
      )}
    </div>
  )
}

export default ContactCard
