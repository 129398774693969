"use client"

import { fetchUsps } from "@/doktorse-api/data"
import { BlokProps } from "@/helpers/StoryblokHelpers"
import { ListingStoreInitializer } from "@/store/ListingStoreInitializer"
import { useConfigStore } from "@/store/config-store"
import { useListingStore } from "@/store/listing-store"
import { getStory } from "@/storyblok/stories"
import { CustomBenefitItem } from "@/types/NonSyncedTypes"
import { ClinicConfigStoryblok, GetListedBlockStoryblok, ListingPageStoryblok } from "@/types/component-types"
import dynamic from "next/dynamic"
import { useEffect, useState } from "react"
import { ISbStoryData } from "storyblok-js-client"

const ListingMainScreen = dynamic(() => import("@/app/(listing)/lista-dig/_templates/ListingMainScreen"), {
  ssr: false
})

export const GetListedBlock = ({ blok }: BlokProps<GetListedBlockStoryblok>) => {
  const config = useConfigStore.getState().config as ClinicConfigStoryblok
  const clinicId = blok.clinic_id || config?.clinic_api_id
  const [customTitle, setCustomTitle] = useState<string | undefined>("")
  const [customItems, setCustomItems] = useState<CustomBenefitItem[] | undefined>([])
  const [listingStory, setListingStory] = useState<ISbStoryData<ListingPageStoryblok> | undefined>()

  useEffect(() => {
    if (!clinicId) return
    const fetchData = async () => {
      return await fetchUsps(clinicId)
    }

    fetchData()
      .then(res => {
        setCustomTitle(res.usps.sellingPoints.header)
        setCustomItems(res.usps.sellingPoints.items)
      })
      .catch(err => {
        throw err
      })
  }, [clinicId])

  useEffect(() => {
    const setupData = async () => {
      const { story } = await getStory<ListingPageStoryblok>("lista-dig")
      if (story) {
        useListingStore.setState({ story: story })
        setListingStory(story)
      }
    }

    setupData()
  }, [])

  return (
    <>
      <ListingStoreInitializer story={listingStory} />
      <ListingMainScreen customTitle={customTitle || undefined} customItems={customItems || undefined} />
    </>
  )
}

export default GetListedBlock
