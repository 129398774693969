"use client"

import { BlokProps, withStoryblokEditable } from "@helpers/StoryblokHelpers"
import { ConfigStoryblok, VaccinationBusLocationPageStoryblok } from "@storyblok-types"

import Breadcrumbs from "@/components/blocks/sections/Breadcrumbs"
import VaccinationPriceTable from "@/components/storyblok-routes/vaccination-location/VaccinationPriceTable"
import { scrollToElement } from "@/helpers/ScrollToElement"
import { useConfigStore } from "@/store/config-store"
import { useStoryStore } from "@/store/story-store"
import { StoryblokServerComponent } from "@storyblok/react/rsc"
import Col from "@ui-library/grid/Col"
import Row from "@ui-library/grid/Row"
import GridWrapper from "@ui-library/grid/Wrapper"
import PageContainer from "@ui-library/layout/PageContainer"
import { useSearchParams } from "next/navigation"
import { useEffect } from "react"

type VaccinationBusLocationPageProps = {
  blok: BlokProps<VaccinationBusLocationPageStoryblok>["blok"]
  isPreview: boolean
}

export type PriceDataProps = {
  label: string
  price?: string
}

export const VaccinationBusLocationPage = ({ blok, isPreview }: VaccinationBusLocationPageProps) => {
  const config = useConfigStore.getState().config as ConfigStoryblok
  const story = useStoryStore.getState().story
  const searchParams = useSearchParams()
  const selectedLocation = searchParams.get("adress")
  const isPreviewLocal = process.env.DOKTOR_ENVIRONMENT === "development" || isPreview

  useEffect(() => {
    if (!selectedLocation) return
    scrollToElement(selectedLocation)
  }, [selectedLocation])

  const priceTableData: PriceDataProps[] = [
    { label: "Vuxen TBE:", price: blok.price_adult !== "" ? blok.price_adult : config.price_adult_default },
    { label: "Barn TBE (0-16 år):", price: blok.price_child !== "" ? blok.price_child : config.price_child_default },
    { label: "Grundskydd:", price: config.default_base_price },
    { label: "Bältros:", price: blok.shingles_price !== "" ? blok.shingles_price : "2500 kr" }
  ]

  return (
    <>
      <PageContainer {...withStoryblokEditable(blok)}>
        <GridWrapper>
          <Row justifyContent="center" justifyContentMobile="center">
            {blok.messages && (
              <Col>
                {blok.messages?.map(nestedBlok => <StoryblokServerComponent blok={nestedBlok} key={nestedBlok._uid} />)}
              </Col>
            )}
            <Col grid={{ xs: 12, lg: 8, xl: 6 }}>
              <Breadcrumbs slug={story?.full_slug} />
              <h1 className="mb-8">Alla hållplatser i {story?.name}</h1>
              <h2 className="m-0">Priser</h2>
              <VaccinationPriceTable tableData={priceTableData} />
              {blok.locations?.map(nestedBlok => (
                <StoryblokServerComponent blok={nestedBlok} key={nestedBlok._uid} isPreview={isPreviewLocal} />
              ))}
            </Col>
          </Row>
        </GridWrapper>
      </PageContainer>
    </>
  )
}

export default VaccinationBusLocationPage
