import { Slot } from "@radix-ui/react-slot"
import { cva, type VariantProps } from "class-variance-authority"

import { cn } from "@/utils/utils"
import React from "react"

const ParagraphVariants = cva("body leading-[1.45] font-normal last:mb-0 ", {
  variants: {
    size: {
      xsmall: "text-xs md:text-xs xl:text-sm font-text tracking-text",
      small: "text-[0.9375rem] xl:text-base font-text tracking-text",
      medium: "text-[1.125rem] xl:text-[1.25rem] tracking-text",
      large: "text-[1.375rem] xl:text-[1.5625rem] tracking-text"
    }
  },
  defaultVariants: {
    size: "small"
  }
})

export interface ParagraphProps
  extends React.ButtonHTMLAttributes<HTMLParagraphElement>,
    VariantProps<typeof ParagraphVariants> {
  asChild?: boolean
  as?: React.ElementType
}

const Paragraph = React.forwardRef<HTMLParagraphElement, ParagraphProps>(
  ({ className, size, asChild = false, as = "p", ...props }, ref) => {
    const Comp = asChild ? Slot : as

    return <Comp className={cn(ParagraphVariants({ size, className }))} ref={ref} {...props} />
  }
)

Paragraph.displayName = "Paragraph"

export { Paragraph, ParagraphVariants }
