import { BlokProps, withStoryblokEditable } from "@helpers/StoryblokHelpers"
import { SeekCareCardStoryblok } from "@storyblok-types"

import { StoryblokServerComponent } from "@storyblok/react/rsc"
import { Paragraph } from "@ui-library/typography/Body"
import Image from "next/image"

export const SeekCareCard = ({ blok }: BlokProps<SeekCareCardStoryblok>) => {
  return (
    <article
      className="flex h-full min-h-[332px] flex-col justify-between whitespace-break-spaces break-words rounded-2xl bg-surface p-6 shadow-elevation-1 lg:p-8"
      {...withStoryblokEditable(blok)}
    >
      <figure className="mb-6 inline-block max-h-[84px] lg:mx-auto lg:max-h-[164px]">
        {blok.image && (
          <Image
            src={blok.image.filename || ""}
            alt={blok.image.alt || ""}
            width={200}
            height={160}
            className="m-0 block h-full w-auto"
          />
        )}
      </figure>
      <div className="mb-6">
        <h3>{blok.heading}</h3>
        <Paragraph size="small">{blok.text}</Paragraph>
      </div>
      {blok.arrow_link &&
        blok.arrow_link?.map(link => <StoryblokServerComponent key={link._uid} blok={link} color="primary-50" />)}
    </article>
  )
}

export default SeekCareCard
