import { BlokProps } from "@helpers/StoryblokHelpers"
import { BoardMembersSectionStoryblok } from "@storyblok-types"
import { StoryblokServerComponent } from "@storyblok/react/rsc"
import Col from "@ui-library/grid/Col"
import Row from "@ui-library/grid/Row"
import GridWrapper from "@ui-library/grid/Wrapper"

export const BoardMembersSection = ({ blok }: BlokProps<BoardMembersSectionStoryblok>) => {
  return (
    <GridWrapper>
      <Row justifyContent="center">
        <Col grid={{ xs: 12, lg: 10, xl: 8 }} className="text-left lg:text-center">
          <h1 className="display-header">{blok.title}</h1>
        </Col>
      </Row>

      {blok.board_members && (
        <Row justifyContent="center">
          <Col grid={{ xs: 12, lg: 10, xl: 8 }}>
            {blok.board_members.map((board_member, i) => {
              return <StoryblokServerComponent blok={board_member} key={`${board_member.name}-${i}`} />
            })}
          </Col>
        </Row>
      )}
    </GridWrapper>
  )
}

export default BoardMembersSection
