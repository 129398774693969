import { ISbStoryData, StoryblokServerComponent } from "@storyblok/react/rsc"

import { BlokProps, withStoryblokEditable } from "@helpers/StoryblokHelpers"
import { ArticleOverviewPageStoryblok } from "@storyblok-types"

import ArticleFiltering from "@/components/storyblok-routes/articles/ArticleFiltering"
import ArticleList from "@/components/storyblok-routes/articles/ArticleList"
import { useStoryStore } from "@/store/story-store"
import { ArticleTagStory } from "@/types/NonSyncedTypes"
import Col from "@ui-library/grid/Col"
import Row from "@ui-library/grid/Row"
import GridWrapper from "@ui-library/grid/Wrapper"
import PageContainer from "@ui-library/layout/PageContainer"
import Pagination from "@ui-library/pagination/Pagination"

interface ArticleOverviewProps {
  blok: BlokProps<ArticleOverviewPageStoryblok>["blok"]
  currentPage: number
  totalPages: number
  articles: ISbStoryData[]
  currentTag: ArticleTagStory | undefined
  articleTags: ArticleTagStory[]
}

export const ArticleOverviewPage = ({
  blok,
  totalPages,
  currentPage,
  articles,
  articleTags,
  currentTag
}: ArticleOverviewProps) => {
  const story = useStoryStore.getState().story

  return (
    <>
      <PageContainer>
        {blok.body?.map(nestedBlok => <StoryblokServerComponent blok={nestedBlok} key={nestedBlok._uid} />)}

        {articleTags && <ArticleFiltering articleTags={articleTags} currentTag={currentTag} />}

        <GridWrapper {...withStoryblokEditable(blok)} className="mt-0 md:mt-0">
          <Row className="mb-16 lg:mb-24">
            <ArticleList currentPage={currentPage} articles={articles} />
          </Row>

          <Row>
            <Col>
              <Pagination slug={story?.full_slug} currentPage={currentPage} totalPages={totalPages} />
            </Col>
          </Row>
        </GridWrapper>
      </PageContainer>
    </>
  )
}

export default ArticleOverviewPage
