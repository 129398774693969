import React, { type JSX } from "react"

import { cn } from "@utils/utils"

export const OrderedList = ({
  children,
  className
}: {
  children: JSX.Element | React.ReactNode
  className?: string
}) => {
  return <ol className={cn("mb-6 ml-8 mr-0 mt-4 list-decimal marker:font-medium", className)}>{children}</ol>
}

export default OrderedList
