/**
 * Click outside hook.
 *
 * @param ref the reference to the element for which click events outside its boundaries will be detected.
 * @param handler the callback that will be executed when a click outside the target element is detected.
 * @param buttonRef the optional reference to the button, ensuring that the callback is not triggered when this button is clicked.
 */

import { RefObject, useEffect } from "react"

export const useOnClickOutside = (
  ref: RefObject<HTMLElement | null>,
  handler: (event: MouseEvent | TouchEvent | KeyboardEvent) => void,
  buttonRef?: RefObject<HTMLElement | null>
) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent | TouchEvent): void => {
      const isClickOutside = ref.current && !ref.current.contains(event.target as Node)
      const isClickOnCloseButton = buttonRef?.current?.contains(event.target as Node)

      if (isClickOnCloseButton) return

      if (isClickOutside) {
        handler(event)
      }
    }

    const handleKeyPress = (event: KeyboardEvent): void => {
      if (event.key === "Escape") {
        handler(event)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    document.addEventListener("touchstart", handleClickOutside)
    document.addEventListener("keydown", handleKeyPress)

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
      document.removeEventListener("touchstart", handleClickOutside)
      document.removeEventListener("keydown", handleKeyPress)
    }
  }, [ref, buttonRef, handler])
}
