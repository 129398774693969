"use client"

import { BlokProps } from "@helpers/StoryblokHelpers"
import { FormSectionStoryblok } from "@storyblok-types"
import { SbBlokData, storyblokEditable } from "@storyblok/react/rsc"

import Col from "@ui-library/grid/Col"
import Row from "@ui-library/grid/Row"
import GridWrapper from "@ui-library/grid/Wrapper"
import Richtext from "@ui-library/richtext/Richtext"
import { H2 } from "@ui-library/typography/Headings"
import dynamic from "next/dynamic"

const ContactForm = dynamic(() => import("@ui-library/form/Form"), {
  ssr: false
})

export const FormSection = ({ blok }: BlokProps<FormSectionStoryblok>) => {
  return (
    <GridWrapper {...storyblokEditable(blok as SbBlokData)}>
      <Row justifyContent="center" justifyContentMobile="start">
        {blok.content && (
          <Col grid={{ xs: 12, lg: 10, xl: 8 }} className="mb-6 lg:mb-8 lg:text-center">
            <H2>{blok.title}</H2>
            <Richtext content={blok.content} />
          </Col>
        )}
        <Col grid={{ xs: 12, lg: 10, xl: 8 }}>
          <ContactForm recipient={blok.form_recipient} formName={blok.form_name} />
        </Col>
      </Row>
    </GridWrapper>
  )
}

export default FormSection
