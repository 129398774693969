import Link from "next/link"

import { Paragraph } from "@/components/ui-library/typography/Body"
import OpenNewTabIcon from "@/layout/icons/OpenNewTabIcon"
import { urlParser } from "@/lib/urlParser"
import { useConfigStore } from "@/store/config-store"
import { useStoryStore } from "@/store/story-store"
import { ClinicItemType, FilterTagStory } from "@/types/NonSyncedTypes"
import { ConfigStoryblok } from "@/types/component-types"
import { findClinicsClinicListBookTime, findClinicsClinicListListingEvent } from "@lib/amplitude/helpers"
import { StoryblokServerComponent } from "@storyblok/react/rsc"
import { Button } from "@ui-library/button/Button"
import { cn } from "@utils/utils"
import dynamic from "next/dynamic"
import { usePathname } from "next/navigation"

const BUTTON_CONTEXTS = {
  EMERGENCY_AID: "lattakut",
  CHILD_CARE: "bvc",
  CANCER_PREVENTION: "prickmottagning"
}

const DoktorIcon = dynamic(() => import("@layout/DoktorIcons"), {
  ssr: true
})

interface ClinicItemProps {
  story: ClinicItemType
  useCollobadoc?: boolean
  className?: string
}

type ListingLink = {
  pathname: string
  query: {
    clinicId?: string
    listingTypeCode?: string
    countyCode?: string
  }
}

const CategoryTag = ({ tag }: { tag: FilterTagStory }) => {
  return (
    <Paragraph size="small" className="inline-flex items-center not-italic text-neutral-50" asChild>
      <em>
        {tag.tagStory?.slug ? (
          <Link
            href={"/" + tag.tagStory?.slug}
            className="inline-flex items-center font-text not-italic tracking-text text-neutral-50"
          >
            <DoktorIcon iconName={tag.icon || "Clinic"} width={18} height={18} />
            <span className="ml-2">{tag.name}</span>
          </Link>
        ) : (
          <>
            <DoktorIcon iconName={tag.icon || "Clinic"} width={18} height={18} />
            <span className="ml-2">{tag.name}</span>
          </>
        )}
      </em>
    </Paragraph>
  )
}

type ListingButtonProps = {
  story: ClinicItemType
  useCollobadoc?: boolean
}
const ClinicItemListButton = ({ story, useCollobadoc }: ListingButtonProps) => {
  const pathname = usePathname()
  const storyContext = useStoryStore.getState()

  if (storyContext.story?.slug === BUTTON_CONTEXTS.EMERGENCY_AID && story.list_button_emergency_aid_override) {
    return <StoryblokServerComponent blok={story.list_button_emergency_aid_override} />
  }

  if (storyContext.story?.slug === BUTTON_CONTEXTS.CHILD_CARE && story.list_button_child_care_override) {
    return <StoryblokServerComponent blok={story.list_button_child_care_override} />
  }

  if (storyContext.story?.slug === BUTTON_CONTEXTS.CANCER_PREVENTION && story.list_button_cancer_prevention_override) {
    return <StoryblokServerComponent blok={story.list_button_cancer_prevention_override} />
  }

  if (story.list_button) {
    return <StoryblokServerComponent blok={story.list_button} />
  }

  const strings = useConfigStore.getState().strings
  const config = useConfigStore.getState().config as ConfigStoryblok
  const enabledRegions = config?.pdf_listing_enabled_in_regions?.split(",")

  let primaryButtonText = strings?.clinics.list
  let listingLink: ListingLink = {
    pathname: `/${process.env.LISTINGPAGE_SLUG}`,
    query: { clinicId: story.clinic_api_id }
  }

  let listingEvent = () => findClinicsClinicListListingEvent(story.clinic_name, "pdf", pathname)
  let linkTarget = "_top"
  let showIcon = false

  if (story.clinic_region_id && enabledRegions) {
    if (!enabledRegions?.includes(story.clinic_region_id)) {
      listingLink = {
        pathname: `https://listning.1177.se/mottagning/${story.clinic_hsa_id}`,
        query: {
          listingTypeCode: story.clinic_region_id === "01" ? "PV" : "HLM",
          countyCode: story.clinic_region_id
        }
      }

      listingEvent = () => findClinicsClinicListListingEvent(story.clinic_name, "1177", pathname)
      linkTarget = "_blank"
      showIcon = true
    }
  }

  if (useCollobadoc && story.collabodoc_url) {
    primaryButtonText = strings?.clinics.makeAppointment
    listingEvent = () => findClinicsClinicListBookTime(story.clinic_name, pathname)
    linkTarget = "_blank"
    showIcon = true

    listingLink = {
      pathname: story.collabodoc_url,
      query: {}
    }
  }

  return (
    <Button variant="primary" asChild>
      <Link href={listingLink} onClick={listingEvent} target={linkTarget}>
        {primaryButtonText}
        {showIcon && <OpenNewTabIcon className="ml-2" width={16} height={16} />}
      </Link>
    </Button>
  )
}

const ClinicItem = ({ story, className, useCollobadoc }: ClinicItemProps) => {
  const finalUrl = urlParser(story.slug)
  const strings = useConfigStore.getState().strings

  return (
    <article className={cn("h-full rounded-2xl bg-surface p-4 shadow-elevation-1 lg:p-6", className)}>
      <div className="flex flex-wrap items-center justify-between">
        <h3 className="mb-4 w-full">
          <Link href={finalUrl || "#"} className="lg:text-primary-50">
            {story.clinic_name}
          </Link>
        </h3>

        {story?.clinic_tags && (
          <div className="flex w-full flex-wrap items-start gap-2 xl:gap-4">
            {story?.clinic_tags?.map(tag => <CategoryTag key={tag.id} tag={tag}></CategoryTag>)}
          </div>
        )}
      </div>

      <div className="mt-6 flex flex-wrap items-center justify-between">
        {story?.address && (
          <>
            <Link href={story.location_url || "#"} target="_blank" rel="noopener noreferrer" className="cursor-pointer">
              <Paragraph size="medium" className="text-neutral-70" asChild>
                <span>
                  {story?.address + ". " || ""}
                  <span className="text-primary-50">{strings?.clinics.find}</span>
                </span>
              </Paragraph>
            </Link>
          </>
        )}

        <div className="mb-0 mt-6 flex w-full items-start gap-x-4 text-center lg:mt-10 lg:inline-flex [&>a]:w-2/4">
          <ClinicItemListButton story={story} useCollobadoc={useCollobadoc} />

          {story.clinic_description_button ? (
            <StoryblokServerComponent blok={story.clinic_description_button} />
          ) : (
            <Button variant="primary_outlined" asChild>
              <Link href={finalUrl || ""}>{strings?.clinics.readMore}</Link>
            </Button>
          )}
        </div>
      </div>
    </article>
  )
}

export default ClinicItem
