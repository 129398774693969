import { BlokProps } from "@/helpers/StoryblokHelpers"
import { SourceLinkStoryblok } from "@/types/component-types"
import Link from "next/link"

export const SourceLink = ({ blok }: BlokProps<SourceLinkStoryblok>) => {
  return (
    <Link
      href={blok.link?.cached_url || "#"}
      target="_blank"
      rel="noopener norefferer"
      className="text-on-surface no-underline"
    >
      {blok.title}
    </Link>
  )
}

export default SourceLink
